import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SidePanel from '../../components/Sidepanel';
import Navbar from '../../components/Navbar';
import Dashboard from '../../pages/dashboard/Dashboard';
import Users from '../../pages/homepage/Users';
import Customers from '../../pages/homepage/Customers';
import Tasks from '../../pages/homepage/Tasks';
import Notes from '../../pages/homepage/Notes';
import { Link } from 'react-router-dom';
import Orders from '../../pages/homepage/Orders';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchAllSalesNoStatus, updateItem, deleteSale, approveSale } from '../../api/api';

export default function Invoices() {
  const navigate = useNavigate();
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(true);
  const [activeScreen, setActiveScreen] = useState('mastersheet');
  const [selectedFile, setSelectedFile] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [filterType, setFilterType] = useState(''); // State to track selected filter type
  const [fromDate, setFromDate] = useState(''); // From date filter
  const [toDate, setToDate] = useState(''); // To date filter

  const toggleSidePanel = () => {
    setIsSidePanelOpen(!isSidePanelOpen);
  };

  const handleMenuItemClick = (screen) => {
    if (screen === 'dashboard') {
      navigate('/homepage');
    } else {
      setActiveScreen(screen);
    }
  };

  const [sales, setSales] = useState([]);
  const [formData, setFormData] = useState({
    item: '',
    itemqty: '',
    buyprice: '',
    sellprice: ''
  });
  const [selectedTask, setSelectedTask] = useState(null);

  useEffect(() => {
    fetchSales();
    setDefaultDateFilters();
  }, []);
  
  // const setDefaultDateFilters = () => {
  //   const today = new Date().toISOString().split('T')[0];
  //   setFromDate(today);
  //   setToDate(today);
  // };

  const setDefaultDateFilters = () => {
    const savedFromDate = localStorage.getItem('fromDate');
    const savedToDate = localStorage.getItem('toDate');
  
    if (savedFromDate && savedToDate) {
      setFromDate(savedFromDate);
      setToDate(savedToDate);
    } else {
      const today = new Date().toISOString().split('T')[0];
      setFromDate(today);
      setToDate(today);
      localStorage.setItem('fromDate', today);
      localStorage.setItem('toDate', today);
    }
  };
  
  

  const fetchSales = async () => {
    try {
      const salesData = await fetchAllSalesNoStatus();
      // console.log('invoices', salesData)
      // Filter sales data to include only invoices with approve_status of 1
      const approvedSales = salesData.filter(sale => sale.approvestatus === 1);
      setSales(approvedSales);
      console.log('approved', approvedSales)
    } catch (error) {
      console.error('Error fetching sales:', error);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateItem(selectedTask.id, formData);
      fetchSales();
      toast.success('Item updated successfully.');
      setSelectedTask(null);
    } catch (error) {
      toast.error('Error updating Item. Please try again.');
    }
  };

  const handleUpdate = (item) => {
    setSelectedTask(item);
    setFormData({
      item: item.item,
      itemqty: item.itemqty
    });
  };

  const handleDelete = async (saleId) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this sale?");
    if (isConfirmed) {
      try {
        await deleteSale(saleId);
        fetchSales();
        toast.success('Sale deleted successfully.');
      } catch (error) {
        toast.error('Error deleting Sale. Please try again.');
      }
    }
  };

  const handleApprove = async (saleId) => {
    const isConfirmed = window.confirm("Are you sure you want to approve this sale?");
    if (isConfirmed) {
      try {
        await approveSale(saleId);
        fetchSales();
        toast.success('Sale approved successfully.');
      } catch (error) {
        toast.error('Error approving Sale. Please try again.');
      }
    }
  };

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Handle filter type change
  const handleFilterTypeChange = (e) => {
    setFilterType(e.target.value);
  };

  // Group sales by saleid and calculate totals
  const groupedSales = sales.reduce((acc, curr) => {
    if (!acc[curr.saleid]) {
      acc[curr.saleid] = {
        saleid: curr.saleid,
        qty: 0,
        cash: 0,
        paymentMethod: curr.payment,
        cnqtytotal: curr.cnqty * curr.saleprice,
        paidtotal: curr.cash + curr.mpesa + curr.bank,
        saleType: curr.saletype,
        salestatus: curr.salestatus,
        customer: curr.customer,
        commision: curr.commision,
        invoicenumber: curr.invoicenumber,
        seller: curr.seller,
        createdAt: curr.createdAt
      };
    }
    acc[curr.saleid].qty += curr.saleqty;
    acc[curr.saleid].cash += curr.saleprice * curr.saleqty;
    return acc;
  }, {});

  // Filter grouped sales based on search query and filter type
  // const filteredSales = Object.values(groupedSales).filter(sale =>
  //   sale.customer.toLowerCase().includes(searchQuery.toLowerCase()) &&
  //   (filterType === '' || sale.saleType === filterType)
  // );

  const filteredSales = Object.values(
    sales.reduce((acc, curr) => {
      if (!acc[curr.saleid]) {
        acc[curr.saleid] = {
          saleid: curr.saleid,
          qty: 0,
          cash: 0,
          paymentMethod: curr.payment,
          cnqtytotal: curr.cnqty * curr.saleprice,
          rvqtytotal: curr.rvqty * curr.saleprice,
          paidtotal: curr.cash + curr.mpesa + curr.bank,
          saleType: curr.saletype,
          salestatus: curr.salestatus,
          customer: curr.customer,
          commision: curr.commision,
          invoicenumber: curr.invoicenumber,
          seller: curr.seller,
          createdAt: curr.createdAt
        };
      }
      acc[curr.saleid].qty += curr.saleqty;
      acc[curr.saleid].cash += curr.saleprice * curr.saleqty;
      return acc;
    }, {})
  ).filter(sale => {
    const saleDate = new Date(sale.createdAt).toISOString().split('T')[0];
    return (
      sale.customer.toLowerCase().includes(searchQuery.toLowerCase()) &&
      (filterType === '' || sale.saleType === filterType) &&
      (!fromDate || saleDate >= fromDate) &&
      (!toDate || saleDate <= toDate)
    );
  });
  

  const sortedSales = filteredSales.sort((a, b) => {
  // Prioritize "Not sold" items (salestatus 0 or 2)
  if ((a.salestatus === 0 || a.salestatus === 2) && (b.salestatus !== 0 && b.salestatus !== 2)) {
    return -1;
  }
  if ((b.salestatus === 0 || b.salestatus === 2) && (a.salestatus !== 0 && a.salestatus !== 2)) {
    return 1;
  }
  // Sort by date from newest to oldest
  return new Date(b.createdAt) - new Date(a.createdAt);
});


  return (
    <>
      <Navbar />
      <div className='homepage'>
        <SidePanel
          isOpen={isSidePanelOpen}
          toggleSidePanel={toggleSidePanel}
          handleMenuItemClick={handleMenuItemClick}
        />
        
        <div className={`container-fluid my-5 ${isSidePanelOpen ? 'expanded' : 'closed'}`}>
          {/* Render different components based on the active screen */}
          {activeScreen === 'mastersheet' && 
          <div className='mt-5'>
          
          <div className='float-end d-flex'>
            {/* Dropdown for filter type */}
            <select className='form-control mx-1' value={filterType} onChange={handleFilterTypeChange}>
              <option value=''>All Types</option>
              <option value='retail'>Retail</option>
              <option value='wholesale'>Wholesale</option>
            </select>

            {/* <input
              type="date"
              className='form-control mx-1'
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
            />
            <input
              type="date"
              className='form-control mx-1'
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
            /> */}

<input
  type="date"
  className="form-control mx-1"
  value={fromDate}
  onChange={(e) => {
    const newFromDate = e.target.value;
    setFromDate(newFromDate);
    localStorage.setItem('fromDate', newFromDate);
  }}
/>
<input
  type="date"
  className="form-control mx-1"
  value={toDate}
  onChange={(e) => {
    const newToDate = e.target.value;
    setToDate(newToDate);
    localStorage.setItem('toDate', newToDate);
  }}
/>


            <input
              className='form-control'
              type="text"
              placeholder="Search customer"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>
          <h5><u className='titilescolor'>Listed for Sale</u></h5>

          <table className="table table-striped mt-2">
            <thead>
              <tr className='theads'>
                <th><strong>#</strong></th>
                {/* <th>Sale ID</th> */}
                <th>Customer</th>
                <th>Invoice NO</th>
                <th>Date</th>
                <th>Creator</th>
                <th>Type</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody className='resizetable'>
              {sortedSales.length < 1 ? (
                <div>
                  <h5>There are Zero Invoices</h5>
                </div>
              ) : (sortedSales.map((sale, index) => (
                <tr key={sale.saleid}>
                  <td>{index + 1}</td>
                  {/* <td>{sale.saleid}</td> */}
                  <td>{sale.customer}</td>
                  <td>{sale.invoicenumber}</td>
                  <td>{sale.createdAt}</td>
                  <td>{sale.seller}</td>
                  <td>{sale.saleType}</td>
                  <td>{sale.salestatus === 0 || sale.salestatus === 2 ? <p className='text-danger fw-bold'>Not sold</p> : <p className='text-success fw-bold'>Sold</p>}</td>
                  <td>
                    {(sale.paidtotal + sale.cnqtytotal + sale.rvqtytotal) > sale.cash ? (
                      <i className="fa-solid fa-circle-dot" style={{color: 'blue'}}></i>
                    ) : (sale.paidtotal + sale.commision)  < sale.cash ? (
                      <i className="fa-solid fa-circle-dot" style={{color: 'red'}}></i>
                    ) : (
                      <i className="fa-solid fa-circle-dot" style={{color: 'green'}}></i>
                    )}
                  </td>

                  <td>
                    {sale.salestatus === 0 || sale.salestatus === 2 ?
                      <>
                        <Link to={`/invoicedetails/${sale.saleid}`}><i className="fa-solid fa-eye fa-1x" style={{ paddingLeft: '10px'}}></i></Link>
                        <i className="fa-solid fa-delete-left fa-1x mx-2 deleteicon" onClick={() => handleDelete(sale.saleid)}></i>
                      </>
                    :
                      <>
                          <Link to={`/solditemsdetails/${sale.saleid}`}><i className="fa-solid fa-eye fa-1x" style={{ paddingLeft: '10px'}}></i></Link>
                      </>
                    }
                  </td>
                </tr>
              )))}
            </tbody>
          </table>
          <ToastContainer />
        </div>
          }
          
          {/* others begin here  */}
          {activeScreen === 'dashboard' && <Dashboard />}
          {activeScreen === 'users' && <Users />}
          {activeScreen === 'customers' && <Customers />}
          {activeScreen === 'tasks' && <Tasks />}
          {activeScreen === 'notes' && <Notes />}
          {activeScreen === 'orders' && <Orders />}
        </div>
      </div>
    </>
  );
}
