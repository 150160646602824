import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import SidePanel from '../../components/Sidepanel';
import Navbar from '../../components/Navbar';
import Dashboard from '../../pages/dashboard/Dashboard';
import Users from '../../pages/homepage/Users';
import Customers from '../../pages/homepage/Customers';
import Tasks from '../../pages/homepage/Tasks';
import Notes from '../../pages/homepage/Notes';
import Orders from '../../pages/homepage/Orders';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { fetchAllSalesStatus1, deleteSale } from '../../api/api';

export default function CustomerOrders2() {

  const { id } = useParams();
  const navigate = useNavigate();
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(true);
  const [activeScreen, setActiveScreen] = useState('mastersheet');
  const [searchQuery, setSearchQuery] = useState('');

  const toggleSidePanel = () => {
    setIsSidePanelOpen(!isSidePanelOpen);
  };

  const handleMenuItemClick = (screen) => {
    if (screen === 'dashboard') {
      navigate('/homepage');
    } else {
      setActiveScreen(screen);
    }
  };

  const [sales, setSales] = useState([]);
  const [formData, setFormData] = useState({
    item: '',
    itemqty: '',
    buyprice: '',
    sellprice: ''
  });
  const [selectedTask, setSelectedTask] = useState(null);

  useEffect(() => {
    fetchSales();
  }, []);

  const fetchSales = async () => {
    try {
      const salesData = await fetchAllSalesStatus1();

      const approvedSales = salesData.filter(sale => sale.customer === id);
      setSales(approvedSales);

      console.log('izo', approvedSales)
      // setSales(salesData);
    } catch (error) {
      console.error('Error fetching sales:', error);
    }
  };

  const handleDelete = async (saleId) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this sale?");
    if (isConfirmed) {
      try {
        await deleteSale(saleId);
        fetchSales();
        toast.success('Order deleted successfully.');
      } catch (error) {
        toast.error('Error deleting Order. Please try again.');
      }
    }
  };

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Group sales by saleid and calculate totals
  const groupedSales = sales.reduce((acc, curr) => {
    if (!acc[curr.saleid]) {
      acc[curr.saleid] = {
        saleid: curr.saleid,
        qty: 0,
        cash: 0,
        paymentMethod: curr.payment,
        saleType: curr.saletype,
        customer: curr.customer,
        createdAt: curr.createdAt
      };
    }
    acc[curr.saleid].qty += curr.saleqty;
    acc[curr.saleid].cash += curr.saleprice * curr.saleqty;
    return acc;
  }, {});

  // Filter grouped sales based on search query
  // approvestatus

  const filteredSales = Object.values(groupedSales)

  return (
    <>
      <Navbar />
      <div className='homepage'>
        <SidePanel
          isOpen={isSidePanelOpen}
          toggleSidePanel={toggleSidePanel}
          handleMenuItemClick={handleMenuItemClick}
        />
        
        <div className={`container-fluid my-5 ${isSidePanelOpen ? 'expanded' : 'closed'}`}>
          {/* Render different components based on the active screen */}
          {activeScreen === 'mastersheet' && 
          <div className='mt-5'>
          <h5><u className='titilescolor'>Your Orders</u></h5>

          <table className="table table-striped mt-2">
            <thead>
              <tr className='theads'>
                <th><strong>#</strong></th>
                {/* <th>Sale ID</th> */}
                <th>Sale QTY</th>
                <th>Date</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {filteredSales.length < 1 ? (
                <div>
                  <h5>There are no orders</h5>
                </div>
              ) : (filteredSales.map((sale, index) => (
                <tr key={sale.saleid}>
                  <td>{index + 1}</td>
                  {/* <td>{sale.saleid}</td> */}
                  <td>{sale.qty}</td>
                  <td>{sale.createdAt}</td>
                  <td>
                    <Link to={`/orderdetails/${sale.saleid}`}><i className="fa-solid fa-eye fa-1x" style={{ paddingLeft: '10px'}}></i></Link>
                    <i className="fa-solid fa-delete-left fa-1x mx-2 deleteicon" onClick={() => handleDelete(sale.saleid)}></i>
                  </td>
                </tr>
              )))}
            </tbody>
          </table>
          <ToastContainer />
        </div>
          }
          
          
          {/* others begin here  */}
          {activeScreen === 'dashboard' && <Dashboard />}
          {activeScreen === 'users' && <Users />}
          {activeScreen === 'customers' && <Customers />}
          {activeScreen === 'tasks' && <Tasks />}
          {activeScreen === 'notes' && <Notes />}
          {activeScreen === 'orders' && <Orders />}
        </div>
      </div>
    </>
  );
}
