import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Dashboard from '../../pages/dashboard/Dashboard';
import Users from '../../pages/homepage/Users';
import Customers from '../../pages/homepage/Customers';
import Tasks from '../../pages/homepage/Tasks';
import Notes from '../../pages/homepage/Notes';
import Orders from '../../pages/homepage/Orders';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchAllSalesStatusForWholeSale, fetchAllSalesW, creatingWholeSale, closeWholesale, updateqtyforwhoelsaleitem, updateqtyforwhoelsaleitemWH, handlePriceChangewholesale } from '../../api/api';

export default function Wholesale() {
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(true);
  const [activeScreen, setActiveScreen] = useState('mastersheet');
  const [items, setItems] = useState([]);
  const inputRefs = useRef({});
  const [selectedLabel, setSelectedLabel] = useState('');

  useEffect(() => {
    fetchItems(); // Fetch items initially
  
    const interval = setInterval(() => {
      fetchItems(); // Fetch items every 5 seconds
    }, 5000);
  
    // Cleanup the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);
  
  
  const fetchItems = async () => {
    try {
      // const allItems = await fetchAllSalesStatusForWholeSale();
      // const filteredItems = await fetchAllSalesStatusForWholeSale();
      // // Filter out items with saletypestatus equal to 1
      // const allItems = filteredItems.filter(item => item.saletypestatus !== 1);
      const allItems = await fetchAllSalesW();

    
      const uniqueItems = new Map();
      console.log('***********************wholesale', allItems)
      console.log('Unique items', uniqueItems)

      allItems.forEach(item => {
        if (!uniqueItems.has(item.itemid.id)) {
          uniqueItems.set(item.itemid.id, { ...item, saleqty: {}, total: 0 });
        }
        const currentItem = uniqueItems.get(item.itemid.id);
        currentItem.saleqty[item.customer] = item.saleqty;
        currentItem.total += item.saleqty * item.saleprice;
      });

      setItems(Array.from(uniqueItems.values()));
      console.log(Array.from(uniqueItems.values()));
    } catch (error) {
      console.error('Error fetching items:', error);
    }
  };

  const handlePriceChangewholesaletrigger = async (event, itemIndex, itemId, itemid) => {
    const newItems = [...items]; // Create a copy of the items array
    newItems[itemIndex].saleprice = parseFloat(event.target.value);
    setItems(newItems);
    calculateTotalPerCustomer(); // Recalculate totals after price change

    const response = await handlePriceChangewholesale(itemId, event.target.value, itemid);
    console.log(response)
  };

  const handleQuantityChange = async (event, itemIndex, customer) => {
    try {
        const newItems = [...items]; // Create a copy of the items array
        const newSaleQty = parseInt(event.target.value, 10);
        newItems[itemIndex].saleqty[customer] = newSaleQty;

        // Recalculate total for the item
        newItems[itemIndex].total = Object.values(newItems[itemIndex].saleqty).reduce(
            (acc, qty) => acc + qty * newItems[itemIndex].saleprice,
            0
        );

        // Update the state
        setItems(newItems);

        // Prepare the data to submit to the API
        const updatedItem = {
            id: newItems[itemIndex].id, // Assuming `id` is a unique identifier for the item
            customer,
            saleqty: newSaleQty,
            total: newItems[itemIndex].total,
            saleprice: newItems[itemIndex].saleprice,
            itemid: newItems[itemIndex].itemid.id
        };

        // Make an API call to submit the updated data
        // const response = await updateqtyforwhoelsaleitem(updatedItem);
        const response = await updateqtyforwhoelsaleitemWH(updatedItem);

        if (!response.ok) {
            throw new Error('Failed to update item details');
        }

        console.log('Item updated successfully:', await response.json());
    } catch (error) {
        console.error('Error updating item:', error);
        // Optionally, show a toast or alert for the error
    }
};

  const calculateTotalPerCustomer = () => {
    setItems((prevItems) =>
      prevItems.map((item) => {
        const updatedItem = { ...item };
        updatedItem.total = Object.keys(item.saleqty).reduce((acc, customer) => {
          return acc + item.saleqty[customer] * item.saleprice;
        }, 0);
        return updatedItem;
      })
    );
  };

  const handleCloseWS = async () => {
    const confirmed = window.confirm('Are you sure you want to close the wholesale?');
    if (!confirmed) {
      return;
    }
    
    try {
      await closeWholesale();
      toast.success('Wholesale closed successfully');
      fetchItems();
    } catch (error) {
      console.error('Error closing wholesale', error);
      toast.error('Error closing wholesale');
    }
  };

  const calculateTotalOrders = (itemId) => {
    return items
      .filter(item => item.id === itemId)
      .reduce((acc, item) => acc + Object.values(item.saleqty).reduce((a, b) => a + b, 0), 0);
  };  

  const getCustomers = () => {
    const customersSet = new Set();
    items.forEach(item => {
      Object.keys(item.saleqty).forEach(customer => customersSet.add(customer));
    });
    return Array.from(customersSet);
  };  

// original code 
  const handleSubmit = async (customer, event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    
    const confirmed = window.confirm(`Are you sure you want to submit sales for customer ${customer}?`);
    if (!confirmed) {
      return;
    }
    console.log(`Submitting sales for customer ${customer}`);
    const salesToSubmit = items
      .filter(item => item.saleqty[customer] && item.saleqty[customer] > 0)
      .map(item => ({
        id: item.id,
        itemid: item.itemid.id,
        saleprice: item.saleprice,
        saleqty: item.saleqty[customer],// Dynamically pick qty for the customer
        customer,
        label: item.label,
        saletotal: item.saletotal,
        saleid: item.saleid
      }));
  
    const seller = localStorage.getItem('username');

    console.log("**************************",salesToSubmit)
  
    // Create the sale object
    const saleData = {
      saleType: 'wholesale',
      customer,
      seller,
      items: salesToSubmit,
    };
    
    console.log(`Submitting sales for customer ${customer}:`, saleData);
  
    try {
      await creatingWholeSale(saleData);
      toast.success(`Sales for ${customer} submitted successfully!`);
    } catch (error) {
      console.error('Error submitting sales:', error);
      toast.error(`Error submitting sales for ${customer}.`);
    }
  };

  // Function to handle container selection
  const handleLabelSelect = (label) => {
    setSelectedLabel(label);
  };

  const uniqueLabels = [...new Set(items.map(item => item.label))];

  // Filter items based on search query and selected label
  const filteredItems = items.filter(item =>
    !selectedLabel || item.label === selectedLabel
  );

//   const handleKeyDown = (event, itemIndex, customerIndex) => {
//     const keyCode = event.keyCode || event.which;

//     const totalItems = items.length;
//     const totalCustomers = getCustomers().length;
    
//     const currentInputRef = inputRefs.current[`${itemIndex}-${customerIndex}`];

//     // Prevent form submission when Enter key is pressed
//     if (keyCode === 13) {
//       event.preventDefault();
//       return;
//     }

//     if (keyCode === 37) {
//         // Left arrow key
//         if (customerIndex > 0) {
//             const targetRef = inputRefs.current[`${itemIndex}-${customerIndex - 1}`];
//             targetRef?.focus();
//             targetRef.select();
//             targetRef?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
//         } else if (customerIndex === 0 && itemIndex > 0) {
//             const targetRef = inputRefs.current[`${itemIndex - 1}-${totalCustomers - 1}`];
//             targetRef?.focus();
//             targetRef.select();
//             targetRef?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
//         }
//     } else if (keyCode === 38) {
//         // Up arrow key
//         if (itemIndex > 0) {
//             const targetRef = inputRefs.current[`${itemIndex - 1}-${customerIndex}`];
//             targetRef?.focus();
//             targetRef?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
//         }
//     } else if (keyCode === 39) {
//         // Right arrow key
//         if (customerIndex < totalCustomers - 1) {
//             const targetRef = inputRefs.current[`${itemIndex}-${customerIndex + 1}`];
//             targetRef?.focus();
//             targetRef?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
//         } else if (customerIndex === totalCustomers - 1 && itemIndex < totalItems - 1) {
//             const targetRef = inputRefs.current[`${itemIndex + 1}-0`];
//             targetRef?.focus();
//             targetRef?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
//         }
//     } else if (keyCode === 40) {
//         // Down arrow key
//         if (itemIndex < totalItems - 1) {
//             const targetRef = inputRefs.current[`${itemIndex + 1}-${customerIndex}`];
//             targetRef?.focus();
//             targetRef?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
//         }
//     }

//     // Scroll the table container to ensure the focused input is visible
//     if (currentInputRef) {
//         currentInputRef.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
//     }
// };

const handleKeyDown = (event, itemIndex, customerIndex) => {
  const keyCode = event.keyCode || event.which;

  const totalItems = items.length;
  const totalCustomers = getCustomers().length;
  
  const currentInputRef = inputRefs.current[`${itemIndex}-${customerIndex}`];

  // Prevent form submission when Enter key is pressed
  if (keyCode === 13) {
      event.preventDefault();
      return;
  }

  const focusAndSelect = (targetRef) => {
      if (targetRef) {
          targetRef.focus();
          setTimeout(() => targetRef.select(), 0); // Ensure selection happens after focus
          targetRef.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      }
  };

  if (keyCode === 37) {
      // Left arrow key
      if (customerIndex > 0) {
          const targetRef = inputRefs.current[`${itemIndex}-${customerIndex - 1}`];
          focusAndSelect(targetRef);
      } else if (customerIndex === 0 && itemIndex > 0) {
          const targetRef = inputRefs.current[`${itemIndex - 1}-${totalCustomers - 1}`];
          focusAndSelect(targetRef);
      }
  } else if (keyCode === 38) {
      // Up arrow key
      if (itemIndex > 0) {
          const targetRef = inputRefs.current[`${itemIndex - 1}-${customerIndex}`];
          focusAndSelect(targetRef);
      }
  } else if (keyCode === 39) {
      // Right arrow key
      if (customerIndex < totalCustomers - 1) {
          const targetRef = inputRefs.current[`${itemIndex}-${customerIndex + 1}`];
          focusAndSelect(targetRef);
      } else if (customerIndex === totalCustomers - 1 && itemIndex < totalItems - 1) {
          const targetRef = inputRefs.current[`${itemIndex + 1}-0`];
          focusAndSelect(targetRef);
      }
  } else if (keyCode === 40) {
      // Down arrow key
      if (itemIndex < totalItems - 1) {
          const targetRef = inputRefs.current[`${itemIndex + 1}-${customerIndex}`];
          focusAndSelect(targetRef);
      }
  }

  // Scroll the table container to ensure the focused input is visible
  if (currentInputRef) {
      currentInputRef.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  }
};


  return (
    <>
      {/* <Navbar /> */}
      <div className='homepage'>
        <div className={`container-fluid ${isSidePanelOpen ? 'expanded' : 'closed'}`}>
          {/* Render different components based on the active screen */}
          {activeScreen === 'mastersheet' && (
            <div>
              <div className='mt-1'>
                {/* <h5><u>Wholesale Page</u> </h5> */}

                <Link to='/sales'>
                  <button className='btn btn-danger btn-sm'>Switch to Retail</button>
                </Link>

                <select
                  className='form-control mx-1 float-end w-50'
                  value={selectedLabel}
                  onChange={(e) => handleLabelSelect(e.target.value)}
                >
                  <option value="">All Labels</option>
                  {uniqueLabels.map((label, index) => (
                    <option key={index} value={label}>{label}</option>
                  ))}
                </select>
                  <hr/>
                <form className=''>
                {/* <div className='mt-4' style={{ maxHeight: isFullScreen ? 'none' : '60vh', overflowY: 'auto', overflowX: 'auto', position: isFullScreen ? 'fixed' : 'static', top: 80, left: 0, width: isFullScreen ? '100%' : 'auto', height: isFullScreen ? '100%' : 'auto', zIndex: isFullScreen ? 1000 : 'auto', backgroundColor: isFullScreen ? 'white' : 'transparent' }}> */}
                  <div className=''>
                    <table className='table table-striped' style={{ minWidth: '100%' }}>
                      <thead style={{ position: 'sticky', top: 0, backgroundColor: 'white' }}>
                        <tr>
                          <th className='sticky-column bg-success text-light'>ITEMS</th>
                          <th className='sticky-column bg-success text-light'>QTY</th>
                          <th className='sticky-column bg-success text-light'>ORD</th>
                          <th className='sticky-column bg-success text-light'>BAL</th>
                          <th className='sticky-column bg-success text-light'>Label</th>
                          <th className='sticky-column bg-success text-light'>Price</th>
                          {items.length > 0 && (
                            <React.Fragment>
                              {getCustomers().map((customer, index) => (
                                <th key={index} className='' style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex:-2, fontSize: '0.9rem' }}>
                                  {customer.split(' ').slice(0, 5).join(' ')} {/* Limit to 5 words */}
                                </th>
                              ))}
                            </React.Fragment>
                          )}
                        </tr>
                      </thead>

                      <tbody>
                        {filteredItems.map((item, itemIndex) => {
                          const totalOrders = calculateTotalOrders(item.id);
                          const balance = item.itemid.masterqty - totalOrders;
                          return (
                            <tr key={item.id}>
                              {/* <td>{item.itemid.item}</td> */}
                              <td className='sticky-column' style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', padding: '0rem' }}>{item.itemid.item.substring(0, 25)}</td>
                              <td className='sticky-column text-primary' style={{padding: '0rem 1rem'}}>{item.itemid.masterqty}</td>
                              <td className='sticky-column' style={{padding: '0rem'}}>{totalOrders}</td>
                              <td className={`sticky-column ${totalOrders > balance ? 'text-danger' : 'text-success'}`} style={{padding: '0rem'}}>{balance}</td>
                              <td className='sticky-column' style={{padding: '0rem'}}>{item.label.substring(0, 4)}</td>
                              <td className='stickycolumn-inputssliding' style={{minWidth:'80px', padding: '0rem' }}>
                                <input
                                  min='0'
                                  className='form-control'
                                  value={item.saleprice}
                                  onChange={(e) => handlePriceChangewholesaletrigger(e, itemIndex, item.itemid.id, item.id)}
                                />
                              </td>
                              {getCustomers().map((customer, customerIndex) => (
                                <td className='' key={customerIndex} style={{minWidth:'80px', top: 0, backgroundColor: 'white', zIndex:2, padding: '0rem'}}>
                                  <input
                                    min='0'
                                    className='form-control'
                                    ref={(el) => inputRefs.current[`${itemIndex}-${customerIndex}`] = el}
                                    onKeyDown={(e) => handleKeyDown(e, itemIndex, customerIndex)}
                                    onChange={(e) => handleQuantityChange(e, itemIndex, customer)}
                                    onFocus={(e) => e.target.select()} // Highlight the value on focus
                                    value={item.saleqty[customer] || 0}
                                  />
                                </td>
                              ))}
                            </tr>
                          );
                        })}
                      </tbody>

                      <thead>
                      <tr>
                        <th className="sticky-column"></th>
                        <th className="sticky-column"></th>
                        <th className="sticky-column"></th>
                        <th className="sticky-column"></th>
                        <th className="sticky-column"></th>
                        <th className="sticky-column"></th>
                        {items.length > 0 && (
                          <React.Fragment>
                            {getCustomers().map((customer, index) => (
                              <React.Fragment key={index}>
                                {/* Total Quantity */}
                                <th
                                  className="sticky-column"
                                  style={{
                                    position: 'sticky',
                                    top: 0,
                                    backgroundColor: 'white',
                                    zIndex: 1, // Adjusted to ensure proper visibility
                                    color: 'blue',
                                  }}
                                >
                                  Qty:{' '}
                                  {items.reduce((acc, currentItem) => {
                                    return acc + (currentItem.saleqty[customer] || 0);
                                  }, 0)}
                                </th>
                              </React.Fragment>
                            ))}
                          </React.Fragment>
                        )}

                      </tr>

                        <tr>
                          <th className='sticky-column'></th>
                          <th className='sticky-column'></th>
                          <th className='sticky-column'></th>
                          <th className='sticky-column'></th>
                          <th className='sticky-column'></th>
                          <th className='sticky-column'></th>
                          {items.length > 0 && (
                            <React.Fragment>
                              {getCustomers().map((customer, index) => (
                                <th className='sticky-column' key={index} style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex:-2, color: 'orangered' }}>
                                  {customer} :{' '}
                                  {items.reduce((acc, currentItem) => {
                                    return acc + (currentItem.saleqty[customer] || 0) * currentItem.saleprice;
                                  }, 0)}
                                </th>
                              ))}
                            </React.Fragment>
                          )}

                        </tr>
                        <tr>
                          <th className='sticky-column'></th>
                          <th className='sticky-column'></th>
                          <th className='sticky-column'></th>
                          <th className='sticky-column'></th>
                          <th className='sticky-column'></th>
                          <th className='sticky-column'></th>
                          {items.length > 0 && (
                            <React.Fragment>
                              {getCustomers().map((customer, index) => (
                                <th className='' key={index} >
                                  <button
                                    className='btn btn-success btn-sm'
                                    onClick={(event) => handleSubmit(customer, event)}
                                  >
                                    Submit
                                  </button>
                                </th>
                              ))}
                            </React.Fragment>
                          )}

                        </tr>
                      </thead>
                    </table>
                  </div>
                </form>

                <button className='btn btn-danger btn-sm' onClick={() => handleCloseWS()}>Close W.S</button>

                <ToastContainer />
              </div>
            </div>
          )}

          {/* other code starts here  */}
          {activeScreen === 'dashboard' && <Dashboard />}
          {activeScreen === 'users' && <Users />}
          {activeScreen === 'customers' && <Customers />}
          {activeScreen === 'tasks' && <Tasks />}
          {activeScreen === 'notes' && <Notes />}
          {activeScreen === 'orders' && <Orders />}
        </div>
      </div>
    </>
  );
}