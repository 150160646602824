import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import SidePanel from './Sidepanel';
import Navbar from './Navbar';
import Dashboard from '../pages/dashboard/Dashboard';
import Users from '../pages/homepage/Users';
import Customers from '../pages/homepage/Customers';
import Tasks from '../pages/homepage/Tasks';
import Notes from '../pages/homepage/Notes';
import Orders from '../pages/homepage/Orders';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchsalesDetails, fetchingAllSellers, deleteSaleitem } from '../api/api';

export default function OrderDetails() {
  const { id } = useParams(); // Get the sales id from the route parameters
  const navigate = useNavigate();
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(true);
  const [activeScreen, setActiveScreen] = useState('mastersheet');
  const [searchQuery, setSearchQuery] = useState('');
  const [sellers, setSellers] = useState([]);
  const [sellid, setSellid] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0); // Initialize totalAmount state

  const toggleSidePanel = () => {
    setIsSidePanelOpen(!isSidePanelOpen);
  };

  const handleMenuItemClick = (screen) => {
    if (screen === 'dashboard') {
      navigate('/homepage');
    } else {
      setActiveScreen(screen);
    }
  };

  // logic 
  const [sales, setSales] = useState([]);
  const [formData, setFormData] = useState({
    sale: '',
    amount: '',
    username: localStorage.getItem('username')
  });
  const [selectedLabel, setSelectedTask] = useState(null);

  useEffect(() => {
    fetchLabelItems();
    fetchSellers()
  }, []);

  const fetchLabelItems = async () => {
    try {
      const sales = await fetchsalesDetails(id);
      console.log('sales',sales);
      setSales(sales);
      setSellid(sales[0].saleid)
      // Calculate total amount for initial sales data
      const initialTotalAmount = sales.reduce((total, sale) => total + parseFloat(sale.saletotal), 0);
      setTotalAmount(initialTotalAmount);
    } catch (error) {
      console.error('Error fetching sales:', error);
    }
  };

  const fetchSellers = async () => {
    try {
      const sellers = await fetchingAllSellers();
      console.log(sellers);
      setSellers(sellers);
    } catch (error) {
      console.error('Error fetching sellers:', error);
    }
  };

  const handleDelete = async (saleId) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this Item?");
    if (isConfirmed) {
      try {
        await deleteSaleitem(saleId);
        fetchLabelItems();
        toast.success('Item deleted successfully.');
      } catch (error) {
        toast.error('Error deleting Item. Please try again.');
      }
    }
  };

  return (
    <>
      <Navbar />
      <div className='homepage'>
        {/* <SidePanel
          isOpen={isSidePanelOpen}
          toggleSidePanel={toggleSidePanel}
          handleMenuItemClick={handleMenuItemClick}
        /> */}

        <div className={`container-fluid  my-5 ${isSidePanelOpen ? 'expanded' : 'closed'}`}>
          {/* Render different components based on the active screen */}
          {activeScreen === 'mastersheet' && 
          <div className='mt-4'>

            <Link to={`/your-orders/${localStorage.getItem('name')}`}>
              <button className='btn btn-sm btn-dark float-end'>Back</button>
            </Link>
            <h6><u className='titilescolor'>Order Details</u></h6>

            <table className="table table-striped">
              <thead>
                <tr className='theads'>
                  <td><strong>#</strong></td>
                  <th>Item</th>
                  <th>Label</th>
                  <th>QTY</th>
                  <th>Status</th>
                  <th></th>                
                </tr>
              </thead>
              <tbody>
                {sales.map((sale, index) => (
                  <tr key={sale.id}>
                    <td>{index + 1}</td>
                    {/* <td>{sale.itemid.item}</td> */}
                    <td>
                      {
                        sale.itemid.item.includes("#1") ? sale.itemid.item.replace("#1", "creame") :
                        sale.itemid.item.includes("#2") ? sale.itemid.item.replace("#2", "pink") :
                        sale.itemid.item.includes("#3") ? sale.itemid.item.replace("#3", "orange") :
                        sale.itemid.item
                      }
                    </td>
                    <td>{sale.label}</td>
                    <td>{sale.saleqty}</td>
                    {/* <td>{sale.saletotal}</td>
                    <td>{sale.saletype}</td> */}
                    <td>{sale.salestatus === 0 ? <p className='text-danger'>Not sold</p> : <p className='text-success'>Sold</p>}</td>
                    {/* <td>{sale.customer}</td> */}
                    {/* <td>
                      <i className="fa-solid fa-delete-left fa-1x deleteicon" onClick={() => handleDelete(sale.id)} style={{ paddingLeft: '10px' }}></i>
                    </td> */}
                    <td>
                      {sale.salestatus === 0 && (
                        <i
                          className="fa-solid fa-delete-left fa-1x deleteicon"
                          onClick={() => handleDelete(sale.id)}
                          style={{ paddingLeft: '10px' }}
                        ></i>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody> 
              <thead>
                <tr className='theads'>
                  <td></td>
                  <th>Total</th>
                  <th></th>
                  <th>{sales.reduce((total, sale) => total + parseInt(sale.saleqty), 0)}</th>
                  <th></th>
                  <th></th>                
                </tr>
              </thead>       
            </table>
            <ToastContainer />
          </div>
          }
          {activeScreen === 'dashboard' && <Dashboard />}
          {activeScreen === 'users' && <Users />}
          {activeScreen === 'customers' && <Customers />}
          {activeScreen === 'tasks' && <Tasks />}
          {activeScreen === 'notes' && <Notes />}
          {activeScreen === 'orders' && <Orders />}
        </div>
      </div>
    </>
  )
}
